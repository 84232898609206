<template>
    <div>
        <el-select
            ref="employeeDropdown"
            v-model="selectedEmployees"
            :placeholder="$t('common.employees')"
            filterable
            clearable
            :multiple="multiple"
            :class="customClass"
            :disabled="disabled"
            :popper-append-to-body="false"
            @change="selectEmployee"
        >
            <el-option-group :label="$t('common.active')">
                <el-option
                    v-for="employee in activeEmployees"
                    :key="employee.uuid"
                    :label="employee.name + ' ' + employee.surname"
                    :value="employee.uuid"
                >
                    <div class="flex items-center -ml-2">
                        <div class="flex items-center justify-center w-8 h-8 mr-2">
                            <img v-if="employee.avatar_th" class="w-8 h-8 rounded-full object-cover" :src="employee.avatar_th">
                            <img v-else class="w-5 h-5 rounded-full" src="@/assets/images/unisex_user.png">
                        </div>
                        <span>{{ employee.name }} {{ employee.surname }}</span>
                    </div>
                </el-option>
            </el-option-group>

            <el-option-group v-if="withArchive" :label="$t('common.archived')">
                <el-option
                    v-for="employee in archivedEmployees"
                    :key="employee.uuid"
                    :label="employee.name + ' ' + employee.surname"
                    :value="employee.uuid"
                />
            </el-option-group>
        </el-select>
    </div>
</template>
<script>
import queryString from 'query-string';

export default {
    props: {
        withArchive: {
            type:    Boolean,
            default: false,
        },
        multiple: {
            type:    Boolean,
            default: false,
        },
        customClass: {
            type:    String,
            default: '',
        },
        withoutQuerySearch: {
            type:    Boolean,
            default: false,
        },
        preSelectedEmployee: {
            type:    String,
            default: '',
        },
        disabled: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            selectedEmployees: [],
            activeEmployees:   [],
            archivedEmployees: [],
        };
    },

    computed: {
        activeEmployeesData() {
            return this.$store.state.employeesActiveDropdown;
        },
        archivedEmployeesData() {
            return this.$store.state.employeesArchivedDropdown;
        },
    },

    watch: {
        activeEmployeesData() {
            this.activeEmployees = this.activeEmployeesData;
        },
        archivedEmployeesData() {
            this.archivedEmployees = this.archivedEmployeesData;
        },
        preSelectedEmployee() {
            this.selectedEmployees = this.preSelectedEmployee;
        },
    },

    created() {
        this.activeEmployees = { ...this.activeEmployeesData };
        this.archivedEmployees = { ...this.archivedEmployeesData };
    },

    mounted() {
        this.$refs.employeeDropdown.focus();

        this.$nextTick(() => {
            this.$refs.employeeDropdown.blur();
            const query = queryString.parse(window.location.search, { sort: false });
            this.selectedEmployees = query.employee = Array.isArray(query.employee) ? query.employee : (query.employee ? [query.employee] : []);
        });
    },

    methods: {
        selectEmployee() {
            if (this.withoutQuerySearch) {
                this.$emit('selected', this.selectedEmployees);
                return;
            }

            const query = queryString.parse(window.location.search, { sort: false });
            query.employee = this.selectedEmployees;
            this.$router.replace({ query }).catch(() => {});
            this.$emit('selected', this.selectedEmployees);
        },

        resetSelection() {
            this.selectedEmployees = [];
        },
    },
};
</script>
